import "../css/main.scss";
import Glide from "@glidejs/glide";

function initAccordion() {
  const list = document.querySelector(".accordion-list");

  if (list) {
    list.querySelectorAll(".accordion").forEach((accordion) => {
      accordion
        .querySelector(".accordion-title")
        .addEventListener("click", (evt) => {
          if (accordion.classList.contains("active")) {
            accordion.classList.remove("active");
          } else {
            accordion.classList.add("active");
          }
        });
    });
  }
}

initAccordion();

function initHeroSlider() {
  var glide = new Glide(".glide", {
    type: "slider",
    perView: 1,
    focusAt: 'center',
    });
  
  glide.mount();
}

function initActionsSlider() {
  var glideAction = new Glide(".glide-action", {
    type: "carousel",
    perView: 2,
    focusAt: "center",
    breakpoints: {
      800: {
        perView: 2,
      },
      480: {
        perView: 1,
      },
    },
  });
  
const controlPrev = document.querySelector(".control-prev");
const controlNext = document.querySelector(".control-next");

controlPrev.addEventListener("click", () => {
  glideAction.go("<");
});

controlNext.addEventListener("click", () => {
  glideAction.go(">");
});

  glideAction.mount();
}

initHeroSlider();
initActionsSlider();